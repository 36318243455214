import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button } from "primereact/button";
import _ from "lodash";
import moment from "moment";

import "./tracking-information.scss";
import markerPurple from "../../assets/img/marker-purple.svg";
import markerGreen from "../../assets/img/marker-green.svg";
import markerRider from "../../assets/img/rider_purple.svg";
import refresh from "../../assets/img/refresh.svg";
import ViewDetails from "./ViewDetails";
import ReactGA from "react-ga4";
import {
  trip_between_status,
  trip_final_status,
} from "../../pages/NewHomePage/utils";

import { MapContainer, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import "leaflet-providers/leaflet-providers.js"; // Import Leaflet Providers
import "leaflet-routing-machine";
import L, { DivIcon } from "leaflet";
import { Marker } from "react-leaflet/Marker";
import theme from "../../services/theme";
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import ImageLinks from "../../services/ImageLinks";
import Text from "../common/Text";

const MovingMarker = ({ icon, data }) => {
  const { lat, lng } = data;
  const [prevPos, setPrevPos] = useState([lat, lng]);

  useEffect(() => {
    if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng]);
  }, [lat, lng, prevPos]);

  return (
    <LeafletTrackingMarker
      icon={icon}
      position={[lat, lng]}
      previousPosition={prevPos}
      duration={1000}
    ></LeafletTrackingMarker>
  );
};

const TrackingDetails = ({
  deliveryInformation,
  rider_data,
  riderId,
  refreshRiderLocationUsingId,
  isLoading,
  buttonDisabled,
  configs,
}) => {
  const [activeIndex, setActiveIndex] = useState([]);
  const [map_loaded, set_map_loaded] = useState(false);
  const map_ref = useRef(null);
  const waypoint_ref = useRef(null);

  const delay_time = useMemo(() => {
    let delay_time_text = "";
    if (
      deliveryInformation?.fulfillment?.status == "PICKED_UP" ||
      deliveryInformation?.fulfillment?.status == "OUT_FOR_DELIVERY" ||
      deliveryInformation?.fulfillment?.status == "REACHED_DELIVERY"
    ) {
      if (
        moment(moment()).isAfter(
          moment(deliveryInformation?.fulfillment?.drop?.eta)
        )
      ) {
        let time_diff_in_min = moment(moment()).diff(
          moment(deliveryInformation?.fulfillment?.drop?.eta),
          "minutes"
        );
        if (time_diff_in_min >= 60) {
          const duration = moment.duration(time_diff_in_min, "minutes");
          const hours = duration.hours();
          const minutes = duration.minutes();
          delay_time_text = `${hours}:${minutes} hours`;
        } else {
          delay_time_text = `${time_diff_in_min} minutes`;
        }
      }
    }

    return delay_time_text;
  }, [deliveryInformation]);

  const onClick = (itemIndex) => {
    ReactGA.event({
      category: "User",
      action: "Logs Viewed",
    });
    let _activeIndex = activeIndex ? [...activeIndex] : [];

    if (_activeIndex.length === 0) {
      _activeIndex.push(itemIndex);
    } else {
      const index = _activeIndex.indexOf(itemIndex);

      if (index === -1) _activeIndex.push(itemIndex);
      else _activeIndex.splice(index, 1);
    }

    setActiveIndex(_activeIndex);
  };

  useEffect(() => {
    if (map_loaded && map_ref.current) {
      // calculate_bounds();
      construct_snap_to_road();
    }
  }, [map_loaded]);

  useEffect(() => {
    const timer = setInterval(() => {
      refreshRiderLocationUsingId(riderId, true);
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const calculate_bounds = () => {
    const markers_bound = [];
    if (!_.isEmpty(rider_data) && rider_data.latitude && rider_data.latitude) {
      markers_bound.push([
        Number(rider_data.latitude),
        Number(rider_data.latitude),
      ]);
    }
    if (
      !_.isEmpty(_.get(deliveryInformation, "customer_detail.address", null))
    ) {
      markers_bound.push([
        Number(deliveryInformation?.customer_detail?.address?.latitude),
        Number(deliveryInformation?.customer_detail?.address?.longitude),
      ]);
    }
    if (!_.isEmpty(_.get(deliveryInformation, "sender_detail.address", null))) {
      markers_bound.push([
        Number(deliveryInformation?.sender_detail?.address?.latitude),
        Number(deliveryInformation?.sender_detail?.address?.longitude),
      ]);
    }
    const bounds = L.latLngBounds(markers_bound);
    map_ref.current.fitBounds(bounds);
  };

  useEffect(() => {
    if (_.isEmpty(rider_data)) return;
    const { latitude, longitude } = rider_data;
    if (
      latitude &&
      longitude &&
      map_ref.current &&
      !trip_final_status.includes(
        _.get(deliveryInformation, "fulfillment.status", "")
      )
    ) {
      // calculate_bounds();
      construct_snap_to_road();
    }
  }, [rider_data]);

  const construct_snap_to_road = () => {
    if (waypoint_ref.current)
      map_ref.current.removeControl(waypoint_ref.current);
    waypoint_ref.current = null;

    if (
      configs.show_rider_route &&
      trip_between_status.includes(
        _.get(deliveryInformation, "fulfillment.status", "")
      )
    ) {
      waypoint_ref.current = L.Routing.control({
        waypoints: [
          L.latLng(rider_data.latitude, rider_data.longitude),
          L.latLng(
            deliveryInformation?.customer_detail?.address.latitude,
            deliveryInformation?.customer_detail?.address.longitude
          ),
        ],
        lineOptions: {
          styles: [
            { color: theme.colors.lightPurple6, opacity: 0.8, weight: 4 },
          ],
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: false,
        createMarker: () => null,
      }).addTo(map_ref.current);
    }
  };

  function calculateBearingAngle(lat1, lon1, lat2, lon2) {
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

    let bearing = Math.atan2(y, x) * (180 / Math.PI);
    bearing = (bearing + 360) % 360;

    return bearing;
  }

  let angle = 0;

  if (
    !_.isEmpty(rider_data) &&
    rider_data?.latitude &&
    rider_data?.longitude &&
    !trip_final_status.includes(
      _.get(deliveryInformation, "fulfillment.status", "")
    )
  ) {
    angle = calculateBearingAngle(
      deliveryInformation?.customer_detail?.address.latitude,
      deliveryInformation?.customer_detail?.address.longitude,
      rider_data?.latitude,
      rider_data?.longitude
    );
  }

  return (
    <div
      className="h-100"
      style={
        window.innerWidth < 767
          ? {
              display: "flex",
              flexDirection: "column-reverse",
            }
          : { position: "relative" }
      }
    >
      <div className="h-100 map-content-height">
        <MapContainer
          ref={map_ref}
          whenReady={() => {
            set_map_loaded(true);
          }}
          center={
            !_.isEmpty(rider_data) &&
            rider_data.latitude &&
            rider_data.longitude
              ? {
                  lat: Number(rider_data?.latitude),
                  lng: Number(rider_data?.longitude),
                }
              : {
                  lat: Number(
                    deliveryInformation?.customer_detail?.address?.latitude
                  ),
                  lng: Number(
                    deliveryInformation?.customer_detail?.address?.longitude
                  ),
                }
          }
          zoom={11}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker
            icon={
              new DivIcon({
                html: `<div>
                  <img src="${
                    _.isEmpty(configs.pickup_marker_image)
                      ? markerGreen
                      : configs.pickup_marker_image
                  }" 
                  width="32px" height="32px"
                  />
                  </div>`,
                iconSize: [0, 0],
              })
            }
            position={[
              deliveryInformation?.sender_detail?.address?.latitude,
              deliveryInformation?.sender_detail?.address?.longitude,
            ]}
          ></Marker>
          <Marker
            icon={
              new DivIcon({
                html: `<div>
                  <img src="${
                    _.isEmpty(configs.drop_marker_image)
                      ? markerPurple
                      : configs.drop_marker_image
                  }" 
                  width="32px" height="32px"
                  />
                  </div>`,
                iconSize: [0, 0],
              })
            }
            position={[
              deliveryInformation?.customer_detail?.address?.latitude,
              deliveryInformation?.customer_detail?.address?.longitude,
            ]}
          ></Marker>

          {!_.isEmpty(rider_data) &&
            rider_data?.latitude &&
            rider_data?.longitude &&
            !trip_final_status.includes(
              _.get(deliveryInformation, "fulfillment.status", "")
            ) && (
              <MovingMarker
                data={{
                  lat: rider_data?.latitude,
                  lng: rider_data?.longitude,
                }}
                icon={
                  new DivIcon({
                    html: `<div style="transform: rotate(${angle}deg) ">
                    <img src="${
                      _.isEmpty(configs.rider_marker_image)
                        ? markerRider
                        : configs.rider_marker_image
                    }" 
                    width="32px" height="32px"
                    />
                    </div>`,
                    iconSize: [0, 0],
                  })
                }
              />
              // <Marker
              //   icon={
              //     new DivIcon({
              //       html: `<div>
              //         <img src="${
              //           _.isEmpty(configs.rider_marker_image)
              //             ? markerRider
              //             : configs.rider_marker_image
              //         }"
              //         width="32px" height="32px"
              //         />
              //         </div>`,
              //       iconSize: [0, 0],
              //     })
              //   }
              //   position={[rider_data?.latitude, rider_data?.longitude]}
              // ></Marker>
            )}
        </MapContainer>

        {_.isEmpty(rider_data) &&
        !trip_final_status.includes(
          _.get(deliveryInformation, "fulfillment.status", "")
        ) ? (
          <Button
            style={{ fontSize: 14, opacity: 1, zIndex: 1000 }}
            className="p-button-raised submit-btn refresh-rider-location-disabled cursor-unset"
            disabled={true}
            label="Rider Location Unavailable"
          />
        ) : !_.isEmpty(rider_data) &&
          !trip_final_status.includes(
            _.get(deliveryInformation, "fulfillment.status", "")
          ) ? (
          <Button
            style={{
              fontSize: 14,
              zIndex: 1000,
            }}
            className={`${
              buttonDisabled
                ? "refresh-rider-location-disabled"
                : "refresh-rider-location-button"
            } p-button-raised submit-btn`}
            label="Refresh Rider Location"
            loading={isLoading}
            disabled={buttonDisabled}
            onClick={() => refreshRiderLocationUsingId(riderId, false)}
            //icon="pi pi-check"
            icon={
              <img src={refresh} alt="refresh" style={{ marginRight: 4 }} />
            }
          />
        ) : null}
      </div>
      <div className="tracking-detail-box w-100">
        <div className="availability-container-content">
          <div className="space-between-container">
            <div>
              {_.has(deliveryInformation, "fulfillment") &&
              (_.get(deliveryInformation, "fulfillment.drop", "") ||
                _.get(deliveryInformation, "fulfillment.pickup.eta", "")) ? (
                <>
                  <div className="status-details">
                    {moment(
                      deliveryInformation.fulfillment.drop?.timestamp
                        ? deliveryInformation.fulfillment.drop.timestamp
                        : deliveryInformation.fulfillment.drop?.eta
                        ? deliveryInformation.fulfillment.drop.eta
                        : deliveryInformation.fulfillment.pickup?.eta
                    ).format("DD MMM YYYY")}
                  </div>
                  <h1
                    className={`${
                      _.get(deliveryInformation, "fulfillment.status", "") ===
                      "DELIVERED"
                        ? "color-green"
                        : _.get(
                            deliveryInformation,
                            "fulfillment.status",
                            ""
                          ) === "UNDELIVERED"
                        ? "color-red"
                        : ""
                    } order-information`}
                  >
                    {moment(
                      deliveryInformation.fulfillment.drop?.timestamp
                        ? deliveryInformation.fulfillment.drop.timestamp
                        : deliveryInformation.fulfillment.drop?.eta
                        ? deliveryInformation.fulfillment.drop.eta
                        : deliveryInformation.fulfillment.pickup?.eta
                    ).format("h:mm")}{" "}
                    <span className="timezone-info">
                      {moment(
                        deliveryInformation.fulfillment.drop?.timestamp
                          ? deliveryInformation.fulfillment.drop.timestamp
                          : deliveryInformation.fulfillment.drop?.eta
                          ? deliveryInformation.fulfillment.drop.eta
                          : deliveryInformation.fulfillment.pickup?.eta
                      ).format("a")}
                    </span>
                    {/* - 02:00
              <span className="timezone-info"> PM </span> */}
                  </h1>
                </>
              ) : (
                <div className="availability-soon">Available Soon!</div>
              )}

              <div className="status-details">
                {deliveryInformation.fulfillment.status === "DELIVERED"
                  ? "Delivery Time"
                  : deliveryInformation.fulfillment.status === "UNDELIVERED"
                  ? "Delivery Attempt Time"
                  : deliveryInformation.fulfillment.drop
                  ? "Estimated Delivery"
                  : "Estimated Pickup"}
              </div>
            </div>
            <div>
              <Button
                icon={
                  activeIndex && activeIndex.some((index) => index === 0)
                    ? "pi pi-angle-up p-button-icon-right margin-right-none"
                    : "pi pi-angle-down p-button-icon-right margin-right-none"
                }
                label={`${
                  activeIndex && activeIndex.some((index) => index === 0)
                    ? "Hide Logs"
                    : "View Logs"
                }`}
                disabled={_.isEmpty(
                  _.get(deliveryInformation, "fulfillment.logs", null)
                )}
                onClick={() => onClick(0)}
                style={{ fontSize: 12, boxShadow: "unset", minWidth: 120 }}
                className="p-button-raised p-button-rounded submit-btn pidge-btn-without-shadow"
              />
            </div>
          </div>
          {!_.isEmpty(delay_time) && (
            <div className="delay-notification">
              <img src={ImageLinks.alert} />
              <Text semi style={{ fontSize: 12, color: theme.colors.red }}>
                Your order is running {delay_time} late. Please contact the
                Rider or support for updates
              </Text>
            </div>
          )}
          <ViewDetails
            logs={_.get(deliveryInformation, "fulfillment.logs", null)}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackingDetails;
